import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App"; // Import your home component
import WeeklyPicks from "./WeeklyPicks"; // Import your results page component
import Standings from "./Standings";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/weekly-picks" element={<WeeklyPicks />} />
        <Route path="/standings" element={<Standings />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
