import "./App.css";
import { useFetch } from "./services/useFetch";

const EXPRESS_BASE_URL = process.env.REACT_APP_EXPRESS_BASE_URL;

export default function WeekPicker(props) {
  const { data, loading, error } = useFetch(EXPRESS_BASE_URL + "/weeks");

  const currentWeek = data && getCurrentWeek();

  function getCurrentWeek() {
    const currentDate = new Date();

    const currentWeekElement = data.find(
      (week) =>
        currentDate.toISOString() >= week?.start_date &&
        currentDate.toISOString() < week?.end_date
    );

    return currentWeekElement?.week;
  }

  if (loading) return <h1>Loading...</h1>;
  if (error) throw error;

  return (
    <>
      <label for="week-select" Style="font-size: calc(8px + 2vmin)">
        Week:&nbsp;
        <select
          id="week-select"
          Style="width: 50px"
          defaultValue={currentWeek}
          onChange={(e) => props.setWeek(() => e.target.value)}
        >
          {data &&
            data.map((week) => {
              return (
                <option key={week._id} value={week?.week}>
                  {week?.week}
                </option>
              );
            })}
        </select>
      </label>
    </>
  );
}
