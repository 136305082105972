import "./App.css";
import PropTypes from "prop-types";

export default function TeamOdds({
  teamName,
  spread,
  spreadOdds,
  moneyline,
  isHomeTeam,
  score,
  scoreDiff,
  completed,
}) {
  function displayTeamOdds(oddsObject, spreadOdds, homeTeam) {
    let isWinningBet = false;
    const [oddsType] = Object.keys(oddsObject);
    const oddsValue = oddsObject[oddsType];

    if (score && oddsType === "spread") {
      isWinningBet = scoreDiff + oddsObject.spread > 0;
    } else if (score && oddsType === "moneyline") {
      isWinningBet = scoreDiff > 0;
    }

    return (
      <>
        <td
          className={`${isWinningBet ? "App-td-winner" : "App-td"} ${
            isWinningBet && completed ? "final-winner" : ""
          } ${homeTeam ? "bottom-border" : ""} align-right`}
        >
          {oddsValue > 0 ? `+${oddsValue}` : oddsValue}{" "}
          {spreadOdds && `(${spreadOdds})`}
        </td>
      </>
    );
  }

  return (
    <tr>
      <td className={`App-td ${isHomeTeam ? "bottom-border" : ""}`}>
        {teamName}
      </td>
      <td className={`App-td ${isHomeTeam ? "bottom-border" : ""} align-right`}>
        {score && score}
      </td>

      {spread !== 0 && displayTeamOdds({ spread }, spreadOdds, isHomeTeam)}
      {moneyline !== 0 && displayTeamOdds({ moneyline }, null, isHomeTeam)}
    </tr>
  );
}
