import "./App.css";
import { useFetch } from "./services/useFetch";
import benihana_image from "./images/benihana_image.png";

const EXPRESS_BASE_URL = process.env.REACT_APP_EXPRESS_BASE_URL;

export default function Standings() {
  const { data, loading, error } = useFetch(EXPRESS_BASE_URL + "/results");

  if (loading) return <h1>Loading...</h1>;
  if (!data) return <h1>Page not found.</h1>;
  if (error) throw error;

  const userStats = calculateUserStats(data);

  function calculateUserStats(data) {
    if (!data) {
      return [];
    }

    const stats = data.reduce((stats, item) => {
      const { nickname, isWinner, odds } = item;

      let userIndex = stats.findIndex((user) => user.nickname === nickname);
      if (userIndex === -1) {
        userIndex = stats.length;
        stats.push({
          nickname,
          wins: 0,
          losses: 0,
          oddsSum: 0,
        });
      }

      if (isWinner) {
        stats[userIndex].wins += 1;
      } else {
        stats[userIndex].losses += 1;
      }
      stats[userIndex].oddsSum += odds;

      return stats;
    }, []);

    const statsWithOddsAvg = stats.map((userStat) => {
      userStat.oddsAverage = Math.round(
        userStat.oddsSum / (userStat.wins + userStat.losses)
      );

      return userStat;
    });

    return statsWithOddsAvg.sort((a, b) => {
      if (a.wins / (a.wins + a.losses) === b.wins / (b.wins + b.losses)) {
        return b.oddsAverage - a.oddsAverage;
      }
      return b.wins / (b.wins + b.losses) - a.wins / (a.wins + a.losses);
    });
  }

  return (
    <div className="App">
      <header className="App-header">
        <h2 Style="text-align: center; font-family: lato_regular;">
          <img src={benihana_image} alt="" /> <br />
          SCOREBOARD
        </h2>
        <table className="Standings-table">
          <thead>
            <tr>
              <th className="App-th align-center header-bold">Bettor</th>
              <th className="App-th align-center header-bold">Wins</th>
              <th className="App-th align-center header-bold">Losses</th>
              <th className="App-th align-center header-bold">Avg Odds</th>
            </tr>
          </thead>
          <tbody>
            {userStats.map((user, index) => (
              <tr
                className={`App-tr-breakline ${
                  userStats.length === index + 1 ? "biggest-loser" : ""
                }`}
                key={index}
              >
                <td className="App-td align-center td-border-top final-score">
                  <span className="pick-nickname">{user.nickname}</span>
                </td>
                <td className="App-td align-center td-border-top final-score">
                  {user.wins}
                </td>
                <td className="App-td align-center td-border-top final-score">
                  {user.losses}
                </td>
                <td className="App-td align-center td-border-top final-score">
                  {user.oddsAverage}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </header>
    </div>
  );
}
