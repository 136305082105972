import "./App.css";
import { useState } from "react";
import { useFetch } from "./services/useFetch";
import { Link } from "react-router-dom";
import Game from "./Game";
import WeekPicker from "./WeekPicker";

const EXPRESS_BASE_URL = process.env.REACT_APP_EXPRESS_BASE_URL;

export default function App() {
  const [week, setWeek] = useState();
  const { data, loading, error } = useFetch(
    EXPRESS_BASE_URL + "/odds?week=" + week
  );

  if (loading) return <h1>Loading...</h1>;
  if (!data) return <h1>Page not found.</h1>;
  if (error) throw error;

  const lastUpdated = findLastUpdatedTime(data);

  function findLastUpdatedTime(jsonData) {
    let lastUpdated;

    jsonData &&
      jsonData.map((game) => {
        lastUpdated =
          game?.bookmakers[
            game?.bookmakers?.findIndex(
              (element) => element.key === "draftkings"
            )
          ]?.last_update;

        return lastUpdated;
      });

    return new Date(lastUpdated)?.toLocaleTimeString([], {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      timeZoneName: "shortGeneric",
    });
  }

  const gamesByDateMap = buildMapOfGamesByDate(data);

  function buildMapOfGamesByDate(jsonData) {
    const gamesByDateMap = new Map();

    jsonData &&
      jsonData.map((game) => {
        const gameDate = new Date(game?.commence_time).toLocaleDateString([], {
          weekday: "short",
          month: "numeric",
          day: "numeric",
          year: "numeric",
        });

        return gamesByDateMap.has(gameDate)
          ? gamesByDateMap.get(gameDate).push(buildDraftKingsGameObject(game))
          : gamesByDateMap.set(gameDate, [buildDraftKingsGameObject(game)]);
      });

    return gamesByDateMap;
  }

  function buildDraftKingsGameObject(game) {
    const draftKingsOdds =
      game?.bookmakers[
        game?.bookmakers.findIndex((element) => element.key === "draftkings")
      ];

    const moneyline = draftKingsOdds?.markets?.find(
      (betType) => betType.key === "h2h"
    );
    const spread = draftKingsOdds?.markets?.find(
      (betType) => betType.key === "spreads"
    );

    const gameObject = {
      startTime: new Date(game?.commence_time).toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        timeZoneName: "shortGeneric",
      }),
      homeTeam: {
        name: game?.home_team,
        spread: spread?.outcomes.find(
          (element) => element?.name === game?.home_team
        ).point,
        moneyline: moneyline?.outcomes.find(
          (element) => element?.name === game?.home_team
        ).price,
        score: game?.game_results[0]?.scores?.find(
          (element) => element?.name === game?.home_team
        ).score,
      },
      awayTeam: {
        name: game?.away_team,
        spread: spread?.outcomes.find(
          (element) => element?.name === game?.away_team
        ).point,
        moneyline: moneyline?.outcomes.find(
          (element) => element?.name === game?.away_team
        ).price,
        score: game?.game_results[0]?.scores?.find(
          (element) => element?.name === game?.away_team
        ).score,
      },
      completed: game?.game_results[0]?.completed,
    };

    return gameObject;
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1 Style="text-align: center;">
          <a href="https://docs.google.com/spreadsheets/d/1cMKV7Qtjh9y4tZ8UrpCWPy9loHZY-gD-pHT9I7Q-CmY/edit?usp=sharing">
            The Poppa Pod Parlay
          </a>
        </h1>
        <div Style="text-align: center; font-size: calc(14px + .75vmin);">
          <em>last updated {lastUpdated}</em>
          <br />
        </div>
        <div Style="text-align: center; font-size: calc(10px + .75vmin);">
          <Link to={`weekly-picks`}>Weekly Picks</Link> <br />
          <Link to={`standings`}>Standings</Link>
          <br />
          <br />
        </div>
        <WeekPicker setWeek={setWeek} />
        {[...gamesByDateMap.keys()].map((gameDay) => {
          return (
            <>
              <table className="App-table">
                <thead>
                  <tr>
                    <th className="App-th-date">{gameDay}</th>
                    <th className="App-th">Score</th>
                    <th className="App-th">Spread</th>
                    <th className="App-th">Moneyline</th>
                  </tr>
                </thead>
                <tbody>
                  {gamesByDateMap.get(gameDay).map((game) => (
                    <Game game={game} />
                  ))}
                </tbody>
              </table>
            </>
          );
        })}
      </header>
    </div>
  );
}
