import TeamOdds from "./TeamOdds";

export default function Game({ game }) {
  function calculateScoreDifference(teamScore, opponentScore) {
    return (teamScore || 0) - (opponentScore || 0);
  }

  function determineWinningPick(gameObject) {
    let isWinningPick = false;
    let scoreDiff = 0;

    if (gameObject?.homeTeam?.spread || gameObject?.homeTeam?.moneyline) {
      scoreDiff = calculateScoreDifference(
        gameObject?.homeTeam?.score,
        gameObject?.awayTeam?.score
      );

      isWinningPick = determinePickOutcomeByPickType(
        gameObject?.homeTeam?.spread,
        scoreDiff
      );
    } else {
      scoreDiff = calculateScoreDifference(
        gameObject?.awayTeam?.score,
        gameObject?.homeTeam?.score
      );

      isWinningPick = determinePickOutcomeByPickType(
        gameObject?.awayTeam?.spread,
        scoreDiff
      );
    }

    return isWinningPick;
  }

  function determinePickOutcomeByPickType(spreadNumber, scoreDifference) {
    return spreadNumber === 0
      ? scoreDifference > 0
      : scoreDifference + spreadNumber > 0;
  }

  return (
    game.homeTeam && (
      <>
        <tr className="App-tr-breakline">
          <td className="App-td">
            <span className={`" ${game.nickname ? "pick-nickname" : ""}`}>
              {game.nickname ? <b>{game.nickname}</b> : game.startTime}
            </span>
            &nbsp;&nbsp;
            {game.nickname && game.completed ? (
              <span className="final-winner">
                {determineWinningPick(game) ? "WINNER" : ""}
              </span>
            ) : (
              ""
            )}
          </td>
          <td className="App-td align-right final-score">
            {game.completed && "Final"}
          </td>
        </tr>
        <TeamOdds
          teamName={game?.awayTeam?.name}
          spread={game?.awayTeam?.spread}
          spreadOdds={game?.awayTeam?.spreadOdds}
          moneyline={game?.awayTeam?.moneyline}
          isHomeTeam={false}
          score={game?.awayTeam?.score}
          scoreDiff={calculateScoreDifference(
            game?.awayTeam?.score,
            game?.homeTeam?.score
          )}
          completed={game?.completed}
        />
        <TeamOdds
          teamName={game?.homeTeam?.name}
          spread={game?.homeTeam?.spread}
          spreadOdds={game?.homeTeam?.spreadOdds}
          moneyline={game?.homeTeam?.moneyline}
          isHomeTeam={true}
          score={game?.homeTeam?.score}
          scoreDiff={calculateScoreDifference(
            game?.homeTeam?.score,
            game?.awayTeam?.score
          )}
          completed={game?.completed}
        />
      </>
    )
  );
}
