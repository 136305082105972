import "./App.css";
import { useState } from "react";
import { useFetch } from "./services/useFetch";
import Game from "./Game";
import WeekPicker from "./WeekPicker";

const EXPRESS_BASE_URL = process.env.REACT_APP_EXPRESS_BASE_URL;

export default function WeeklyPicks() {
  const [week, setWeek] = useState();
  const { data, loading, error } = useFetch(
    EXPRESS_BASE_URL + "/picks?week=" + week
  );

  if (loading) return <h1>Loading...</h1>;
  if (!data) return <h1>Page not found.</h1>;
  if (error) throw error;

  console.log(data);

  const games = buildArrayOfGames(data);

  function buildArrayOfGames(jsonData) {
    const games = [];

    jsonData &&
      jsonData.map((game) => {
        return games.push(buildWeeklyPicksObject(game));
      });

    console.log("games array length", games.length);

    return games;
  }

  function buildWeeklyPicksObject(game) {
    const pickTeam = game?.pick[0]?.team;
    const pickPoints = game?.pick[0]?.points;
    const pickOdds = game?.pick[0]?.odds;

    const gameObject = {
      startTime: new Date(game?.commence_time).toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        timeZoneName: "shortGeneric",
      }),
      username: game?.pick[0]?.username,
      nickname: game?.user_detail[0]?.nickname,
      homeTeam: {
        name: game?.home_team,
        spread:
          pickPoints !== 0 && pickTeam === game?.home_team ? pickPoints : null,
        spreadOdds:
          pickPoints !== 0 && pickTeam === game?.home_team ? pickOdds : null,
        moneyline:
          pickPoints === 0 && pickTeam === game?.home_team ? pickOdds : null,
        score: game?.scores?.find(
          (element) => element?.name === game?.home_team
        ).score,
      },
      awayTeam: {
        name: game?.away_team,
        spread:
          pickPoints !== 0 && pickTeam === game?.away_team ? pickPoints : null,
        spreadOdds:
          pickPoints !== 0 && pickTeam === game?.away_team ? pickOdds : null,
        moneyline:
          pickPoints === 0 && pickTeam === game?.away_team ? pickOdds : null,
        score: game?.scores?.find(
          (element) => element?.name === game?.away_team
        ).score,
      },
      completed: game?.completed,
    };

    return gameObject;
  }

  return (
    <>
      <div className="App">
        <header className="App-header">
          <h1 Style="text-align: center;">
            <a href="https://docs.google.com/spreadsheets/d/1cMKV7Qtjh9y4tZ8UrpCWPy9loHZY-gD-pHT9I7Q-CmY/edit?usp=sharing">
              The Poppa Pod Parlay
            </a>
          </h1>
          <WeekPicker setWeek={setWeek} />
          <table className="App-table">
            <thead>
              <tr>
                <th className="App-th-date">Week {week}</th>
                <th className="App-th">Score</th>
                <th className="App-th">Spread</th>
                <th className="App-th">ML</th>
              </tr>
            </thead>
            <tbody>
              {games.map((game) => {
                return <Game game={game} />;
              })}
            </tbody>
          </table>
        </header>
      </div>
    </>
  );
}
